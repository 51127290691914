import initialState from "../store/initialState";

export const vendorCreditsReducer = (state = initialState.vendorCredits, action) => {
  switch (action.type) {
    case "GET_VENDOR_CREDITS_LIST_SUCCESS":
      return {...state, vendorCreditsList: action.list}
    case "GET_VENDOR_CREDITS_LIST_FAILURE":
      return {...state, vendorCreditsList: {},}
    case "GET_VENDOR_CREDITS_STATUSES":
      return { ...state, vendorCreditsStatuses: action.statuses };
    default:
      return state;
  }
};
