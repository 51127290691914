import React from "react";

const PasswordRevealIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="13.273"
      viewBox="0 0 30 19.91"
      {...props}
    >
      <path
        d="M15 0a13.96 13.96 0 00-4.85.91l1.57 1.56A11.93 11.93 0 0115 2c6.97 0 12.56 6.59 12.99 7.96a14.25 14.25 0 01-4.15 4.58l1.43 1.42c2.87-2.25 4.73-4.83 4.73-6C30 7.66 22.91 0 15 0zm3.39 9.11l1.99 1.98a5.22 5.22 0 00.12-1.13A5.5 5.5 0 0015 4.48a5.38 5.38 0 00-1.14.12l1.99 1.98a3.49 3.49 0 012.54 2.53zm5.23 8.02l-1.44-1.44-2.65-2.63-1.45-1.45-4.74-4.72-1.45-1.44-2.22-2.21-1.48-1.47L6.71.29a1.03 1.03 0 00-1.42 0 1 1 0 000 1.42l1.09 1.08C2.59 5.23 0 8.57 0 9.96c0 2.3 7.09 9.95 15 9.95a15.05 15.05 0 006.81-1.76l1.48 1.48a1.08 1.08 0 00.71.28 1.06 1.06 0 00.71-.28 1 1 0 000-1.42zm-6.96-4.1a3.55 3.55 0 01-1.66.41 3.5 3.5 0 01-3.5-3.48 3.37 3.37 0 01.42-1.65zM15 17.92c-6.97 0-12.56-6.59-12.99-7.96.27-.85 2.5-3.68 5.81-5.73l2.65 2.63a5.43 5.43 0 00-.97 3.1 5.5 5.5 0 005.5 5.47 5.43 5.43 0 003.11-.96l2.22 2.21A12.76 12.76 0 0115 17.92z"
        className="icon-fill--current"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default PasswordRevealIcon;
