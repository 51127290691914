import axios from 'axios';
import {
  ILS_API_BASE_URL,
  applicationManagement,
  leadManagement,
} from 'src/config';
import AuthAxios from '../helpers/AxiosAuth';

export const getRolesAndSetPermissions = async (token, history, targetUrl) => {
  const isSSOFlag = localStorage.getItem('isSSO') === 'true';
  const isMedicareCenterSso =
    localStorage.getItem('IsMedicareCenterSso') === 'true';
  const permissionsURI = `${ILS_API_BASE_URL}${applicationManagement}/permissions?isSSO=${isSSOFlag}&IsMedicareCenterSso=${isMedicareCenterSso}`;

  try {
    const { data } = await axios.get(permissionsURI, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    localStorage.setItem('accessToken', token);
    localStorage.setItem('ref_token', data.referenceToken);
    localStorage.setItem('isLoggedIn', true);
    localStorage.setItem('roper', JSON.stringify(data));
    localStorage.setItem(
      'isTermsAndConditionsRequired',
      data.isTermsAndConditionsRequired,
    );
    if (
      data?.userNotifications?.some(
        (notification) => notification?.hasBeenRead === false,
      )
    ) {
      localStorage.setItem('unreadNotifications', 'true');
    } else {
      // Optionally, clearing the item from localStorage if all notifications are read or No list
      localStorage.removeItem('unreadNotifications');
    }

    // Redirect to targetUrl if it exists
    if (data.isNewUser) {
      history.push({
        pathname: '/bu',
        state: { targetUrl: `${targetUrl}` }, // Pass any state you need here
      });
    } else if (targetUrl) {
      history.push(targetUrl);
    } else {
      AuthAxios.post(`${applicationManagement}/users/login`);
      getCartInfoAndClear();
      history.push('/');
    }
  } catch (error) {
    console.error('Error fetching permissions:', error);
    localStorage.clear();
    localStorage.setItem(
      'errorMsg',
      'Please contact admin to get more information.',
    );
    window.location.replace('/error');
  }
};

export const getCartInfoAndClear = async () => {
  AuthAxios.get(`${leadManagement}/cart/current`)
    .then((response) => {
      if (response?.data?.cartId) {
        handleCancelCart(response?.data?.cartId);
      }
    })
    .catch((error) => console.error(error));
};

export const handleCancelCart = async (cartId) => {
  AuthAxios.delete(`${leadManagement}/cart/${cartId}`)
    .then(() => {
      return;
    })
    .catch((error) => console.error(error));
};
