import initialState from "../store/initialState";

export const eGiftsReducer = (state = initialState.eGifts, action) => {
  switch (action.type) {
    case "GET_EGIFTS_LIST_SUCCESS":
      return {...state, eGiftsList: action.list}
    case "GET_EGIFTS_LIST_FAILURE":
      return {...state, eGiftsList: {},}
    case "GET_EGIFT_STATUSES":
      return { ...state, eGiftStatuses: action.statuses };
    default:
      return state;
  }
};
