import initialState from "../store/initialState";

export const userProfilesReducer = (state = initialState.userProfiles, action) => {
  switch (action.type) {
    case "GET_USER_PROFILES_LOADING":
      return { ...state, userprofilesLoading: action.bool };
    case "GET_USER_PROFILES_SUCCESS":
      return { ...state, userprofiles: action.userprofiles };
    case "GET_USER_PROFILES_TOTAL_COUNT":
      return {
        ...state,
        userprofilesTotalCount: action.userprofilesTotalCount,
      };
    case "GET_USER_PROFILES_STATUSES":
      return {
        ...state,
        userProfileStatuses: action.statuses,
      };
    default:
      return state;
  }
};
