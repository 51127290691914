import AuthAxios from 'src/app/helpers/AxiosAuth';
import { allLeadPartnersOption, Constants } from 'src/app/utils/Constants';
import {
  ILS_API_BASE_URL,
  applicationManagement,
  leadManagement,
} from 'src/config';
import { toast } from 'react-toastify';
import authService from 'src/app/redux/services/authService';
import Swal from 'sweetalert2';

export const setLoginStatus = (isLogin) => {
  return { type: 'SET_LOGIN_STATUS', isLogin };
};
export const setStatesData = (states) => {
  return { type: 'STATES_FETCHED', states };
};
export const setCountysData = (countys) => {
  return { type: 'COUNTYS_FETCHED', countys };
};
export const setLeadTypesFetchedSuccess = (leadTypes) => {
  return { type: 'LEAD_TYPES_FETCHED', leadTypes };
};
export const setRoles = (roles) => {
  return { type: 'GET_ROLES_PERMISSIONS', roles };
};
export const setPermissionsStatus = (permission) => {
  return { type: 'GET_STATUS_OF_PERMISSIONS', permission };
};
export const setSpinnerOn = (spinterm) => {
  return { type: 'SET_SPINNER_STATUS', spinterm };
};
export const setLeadGroupTypesFetchedSuccess = (leadGroupTypes) => {
  return { type: 'LEAD_GROUP_TYPES_FETCHED', leadGroupTypes };
};
export const setBusinessUnitsFetchedSuccess = (businessUnits) => {
  return { type: 'BUSINESS_UNITS_FETCHED', businessUnits };
};
export const getInterestsAnnuityData = (interestAnnuity) => {
  return { type: 'GET_INTEREST_ANNUITY_DATA', interestAnnuity };
};

export const getInterestsHealthData = (interestHealth) => {
  return { type: 'GET_INTEREST_HEALTH_DATA', interestHealth };
};

export const getInterestsLifeData = (interestLife) => {
  return { type: 'GET_INTEREST_LIFE_DATA', interestLife };
};

export const getInterestsMedicareData = (interestMedicare) => {
  return { type: 'GET_INTEREST_MEDICARE_DATA', interestMedicare };
};

export const getObjectionsData = (objection) => {
  return { type: 'GET_OBJECTION_DATA', objection };
};

export const getLeadStatusFetchedSuccess = (leadStatus) => {
  return { type: 'GET_LEAD_STATUS_DATA', leadStatus };
};

export const getLeadStatusesFetchedSuccess = (leadStatuses) => {
  return { type: 'GET_LEAD_STATUSES_DATA', leadStatuses };
};

export const getLeadVendorsData = (leadVendors) => {
  return { type: 'GET_LEAD_VENDOR_DATA', leadVendors };
};

export const setLeadPartnersData = (leadPartners) => {
  return { type: 'SET_LEAD_PARTNERS', leadPartners };
};

export const setCashDetails = (cashDetails) => {
  return { type: 'SET_CASH_DETAILS', cashDetails };
};

export const setIsGetCredit = (bool) => {
  return { type: 'IS_GET_CREDIT_DETAILS', bool };
};

export const setIsAddFundsForRealtime = (bool) => {
  return { type: 'IS_ADD_FUNDS_FOR_REALTIME', bool };
};

export const setIsReloadPayments = (bool) => {
  return { type: 'IS_RELOAD_PAYMENTS', bool };
};

export const setPaymentFromUser = (bool) => {
  return { type: 'GET_PAYMENT_FROM_USER', bool };
};

export const setIsLogoutModal = (bool) => {
  return { type: 'SET_IS_LOGOUT_MODAL', bool };
};

export const setVoucherAmount = (amt) => {
  return { type: 'SET_VOUCHER_AMOUNT', amt };
};

export const setIsAddFundsModal = (bool) => {
  return { type: 'SET_IS_ADD_FUNDS_MODAL', bool };
};

export const setIsAutomateModal = (bool) => {
  return { type: 'SET_IS_AUTOMATE_MODAL', bool };
};

export const setIsAutomateAndSaveMode = (bool) => {
  return { type: 'SET_IS_AUTOMATE_AND_SAVE_MODE', bool };
};

export const setShowAutomateAndSaveTurnOffPopup = (bool) => {
  return { type: 'SHOW_AUTOMATE_AND_SAVE_TURN_OFF_POPUP', bool };
};

export const setIsContactSupportModal = (bool) => {
  return { type: 'SET_IS_CONTACT_SUPPORT_MODAL', bool };
};

export const setUserTogglePreferences = (payload) => {
  return { type: 'SET_USER_TOGGLE_PREFERENCES', payload };
};

export const setProductsList = (products) => {
  return { type: 'SET_PRODUCTS_LIST', products };
};

export const setProductCategoryList = (productCategories) => {
  return { type: 'SET_PRODUCT_CATEGORY_LIST', productCategories };
};

export const setPurchaseTypesList = (purchaseTypes) => {
  return { type: 'SET_PURCHASE_TYPES_LIST', purchaseTypes };
};

export const setCreditRequestStatuses = (creditRequestStatuses) => {
  return { type: 'SET_CREDIT_REQUEST_STATUSES', creditRequestStatuses };
};

export const setLeadsAssignMode = (mode) => {
  return { type: 'SET_LEADS_ASSIGN_MODE', mode };
};

export const setApplicationBusinessUnits = (businessUnits) => {
  return { type: 'SET_APPLICATION_BUSINESS_UNITS', businessUnits };
};

export const setUserBusinessUnits = (userBusinessUnits) => {
  return { type: 'SET_USER_REGISTERED_BUSINESS_UNITS', userBusinessUnits };
};

export const setDaysOfWeek = (daysOfWeek) => {
  return { type: 'DAYSOFWEEK_SUCCESS', daysOfWeek };
};

export const setUserAppIntegrations = (userAppIntegrations) => {
  return { type: 'SET_USER_APP_INTEGRATIONS', userAppIntegrations };
};

export const setIsBannersVisible = (isBannersVisible) => {
  return { type: 'SET_IS_BANNERS_VISIBLE', isBannersVisible };
};

export const setLayoutHeights = (layoutHeights) => {
  return { type: 'SET_LAYOUT_HEIGHTS', layoutHeights };
};

export const setIsListScreenError = (isListScreenError) => {
  return { type: 'SET_LIST_SCREEN_ERROR', isListScreenError };
};

export const setIsViewEditScreenError = (isViewEditScreenError) => {
  return { type: 'SET_VIEWEDIT_SCREEN_ERROR', isViewEditScreenError };
};

export const setShowAgentReferralModal = (showAgentReferralModal) => {
  return { type: 'SET_SHOW_AGENT_REFERRAL_MODAL', showAgentReferralModal };
};

export const setUserLeadPartners = (userLeadPartners) => {
  return { type: 'SET_USER_LEAD_PARTNERS', userLeadPartners };
};

export const getApplicationBusinessUnits = () => {
  return (dispatch) => {
    const getBusinessUnitsUri = `${applicationManagement}/BusinessUnits`;
    AuthAxios.get(getBusinessUnitsUri)
      .then((json) => {
        const businessUnitsOptions = json?.data
          ?.filter(
            (option) =>
              -1 ===
                Constants.ExcludeBusinessUnits.indexOf(
                  option.businessUnitIdIdentity,
                ) && !option.isInactive,
          )
          .map((item) => ({
            ...item,
            display: item.name,
            label: item.name,
            value: item.businessUnitIdIdentity,
          }));
        dispatch(setApplicationBusinessUnits(businessUnitsOptions));
      })
      .catch(() => {
        dispatch(setApplicationBusinessUnits(null));
      });
  };
};

export const postRequestAccessForBusinessUnits = (
  businessUnitIdIdentities,
  callback = () => {},
  isExistingUser = false,
) => {
  return (dispatch) => {
    let isMedicareCenterSso =
      localStorage.getItem('IsMedicareCenterSso') === 'true' ? 'true' : 'false';
    let postBusinessUnitsToAddUri = `/applicationManagement/permissions/newUser`;
    postBusinessUnitsToAddUri += `?businessUnitIdIdentities=${businessUnitIdIdentities}&isMedicareCenterSso=${isMedicareCenterSso}`;
    if (isExistingUser) {
      postBusinessUnitsToAddUri += `&isExistingUser=${isExistingUser}`;
    }
    dispatch(setSpinnerOn(true));
    AuthAxios.post(postBusinessUnitsToAddUri)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setSpinnerOn(false));
          callback();
        }
      })
      .catch((error) => {
        toast.error(`${error?.response?.data}`);
        dispatch(setSpinnerOn(false));
      });
  };
};

export const handleSaveNewRequestToAddBusinessUnit = (
  payload,
  callback = () => {},
) => {
  return (dispatch) => {
    dispatch(setSpinnerOn(true));
    const buData = { ...payload };
    let addNewBuUri = `/applicationManagement/BusinessUnits/newrequest`;
    return AuthAxios.post(addNewBuUri, buData)
      .then((res) => {
        dispatch(setSpinnerOn(false));
        if (res.status === 200) {
          callback(res);
        }
      })
      .catch((error) => {
        dispatch(setSpinnerOn(false));
      });
  };
};

export const deleteUserBusinessUnits = (
  userBusinessUnitIdIdentities,
  callback = () => {},
) => {
  return async (dispatch) => {
    const queryParams = userBusinessUnitIdIdentities
      .map((id) => `userBusinessUnitIdIdentities=${id}`)
      .join('&');
    dispatch(setSpinnerOn(true));
    const deleteBusinessUnitsUri = `applicationManagement/users/userBusinessUnits?${queryParams}`;
    return await AuthAxios.delete(deleteBusinessUnitsUri)
      .then((response) => {
        dispatch(setSpinnerOn(false));
        if (response?.status === 200) {
          callback(response);
          authService.getRoles().then((response) => {
            if (response?.status === 200) {
              response.text().then((result) => {
                localStorage.setItem('roper', result);
                toast.success('Selected Organizations deleted successfully!', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                });
              });
            }
          });
        }
      })
      .catch((error) => {
        dispatch(setSpinnerOn(false));
        callback(error);
      });
  };
};

export const postMiscellaneousOrder = async (payload) => {
  const walmartRetailSubmitURI = '/leadManagement/cart/miscellaneousOrder';
  return await AuthAxios.post(walmartRetailSubmitURI, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      Swal.fire({
        title: 'Oops',
        text:
          error?.response?.data ||
          error?.response?.data?.Message ||
          error?.response?.data?.title ||
          error?.response?.statusText,
        icon: 'error',
        confirmButtonColor: '#39f',
      });
    });
};

export const getUserProfileDetails = async () => {
  const userInfo = JSON.parse(localStorage.getItem('roper'));
  return AuthAxios.get(`applicationManagement/users/${userInfo?.userId}`)
    .then((response) => {
      if (response.status === 200) {
        return response;
      }
    })
    .catch((error) => {
      return error?.response;
    });
};

export const updateUserPreferences = async (preferences) => {
  try {
    const response = await AuthAxios.post(
      `${ILS_API_BASE_URL}${applicationManagement}/users/preference`,
      preferences,
    );
    if (response?.status === 200) {
      return response;
    } else {
      // Throw an error with the response data or a generic message if no data is available
      throw new Error(
        response || 'An error occurred while updating the preference',
      );
    }
  } catch (error) {
    // Handle any errors here, such as by displaying an error message
    toast.error(error?.response?.data || error?.response, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    });
    return error;
  }
};

export const getUserAppIntegrations = (params) => {
  return async (dispatch) => {
    dispatch(setSpinnerOn(true));
    let userAppIntegrationsBasedOnParamsURI = `${applicationManagement}/userAppIntegrations`;
    if (params?.isIncludeInactive) {
      userAppIntegrationsBasedOnParamsURI += `?isIncludeInactive=${params?.isIncludeInactive}`;
    }
    try {
      const response = await AuthAxios.get(userAppIntegrationsBasedOnParamsURI);
      dispatch(setSpinnerOn(false));
      dispatch(setUserAppIntegrations(response?.data));
    } catch (error) {
      dispatch(setSpinnerOn(false));
    }
  };
};

export const activateUserAppIntegration = async (reqBody) => {
  try {
    const response = await AuthAxios.post(
      `${applicationManagement}/userAppIntegrations`,
      reqBody,
    );
    if (response?.status === 200) {
      return response;
    } else {
      const errorMessage =
        response?.data?.message ||
        response?.data ||
        'An error occurred while updating the userAppIntegrations';
      throw new Error(errorMessage);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRoles = async () => {
  const isSSOFlag = localStorage.getItem('isSSO') === 'true';
  const isMedicareCenterSso =
    localStorage.getItem('IsMedicareCenterSso') === 'true';
  const permissionsURI = `${applicationManagement}/permissions?isSSO=${isSSOFlag}&IsMedicareCenterSso=${isMedicareCenterSso}`;
  try {
    const response = await AuthAxios.get(permissionsURI);
    if (response?.status === 200) {
      localStorage.setItem('roper', JSON.stringify(response?.data));
      localStorage.setItem(
        'isShowLeadDispositionNotification',
        response?.data?.isShowLeadDispositionNotification,
      );
      localStorage.setItem(
        'isToggleNotificationDismissed',
        response?.data?.isToggleNotificationDismissed,
      );
      return response?.data;
    } else {
      const errorMessage =
        response?.data?.message ||
        response?.data ||
        'An error occurred while getting the permissions';
      throw new Error(errorMessage);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deActivateuserAppIntegration = async (params) => {
  let deleteUserAppIntegrationURI = `${ILS_API_BASE_URL}${applicationManagement}/userAppIntegrations`;
  if (params?.thirdPartyAppIdIdentity) {
    deleteUserAppIntegrationURI += `?thirdPartyAppIdIdentity=${params?.thirdPartyAppIdIdentity}`;
  }
  try {
    const response = await AuthAxios.delete(deleteUserAppIntegrationURI);
    if (response?.status === 200) {
      return response;
    } else {
      const errorMessage =
        response?.data?.message ||
        response?.data ||
        'An error occurred while deleting the userAppIntegrations';
      throw new Error(errorMessage);
    }
  } catch (error) {
    return Promise.reject(
      error instanceof Error ? error : new Error(JSON.stringify(error)),
    );
  }
};

export const getBannersData = async () => {
  try {
    const response = await AuthAxios.get(`${applicationManagement}/banners`);
    const result = response?.data;
    const data = await result?.sort((x, y) => x.sortOrder - y.sortOrder);
    return data;
  } catch (error) {
    return Promise.reject(
      error instanceof Error ? error : new Error(JSON.stringify(error)),
    );
  }
};

export const updateUserNotificationReadStatus = async (userNotificationId) => {
  try {
    const response = await AuthAxios.put(
      `${applicationManagement}/users/userNotifications/${userNotificationId}/status`,
    );
    return response?.data;
  } catch (error) {
    return Promise.reject(
      new Error(error?.response?.data || 'An unexpected error occurred'),
    );
  }
};

export const getPartnersData = async () => {
  try {
    const response = await AuthAxios.get(
      `${leadManagement}/leadtypes/leadPartners`,
    );
    return response?.data;
  } catch (error) {
    return Promise.reject(
      error instanceof Error ? error : new Error(JSON.stringify(error)),
    );
  }
};

export const getUserLeadPartners = () => async (dispatch) => {
  try {
    const response = await AuthAxios.get(
      '/leadManagement/leadtypes/leadPartners/user',
    );
    const modifiedResponse = response.data.map((item) => ({
      ...item,
      label: item?.displayValue,
      value: item?.leadPartnerIdIdentity,
    }));
    const options = [allLeadPartnersOption, ...modifiedResponse];
    dispatch(setUserLeadPartners(options));
  } catch (err) {
    console.error(err?.response?.data?.message);
    dispatch(setUserLeadPartners(null));
  }
};
