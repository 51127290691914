import {
  UPDATE_USERS_SEARCH_PARAMS,
  USERS_SEARCH_FAILURE,
  USERS_SEARCH_REQUEST,
  USERS_SEARCH_SUCCESS,
  USERS_SELECTED_RECORDS,
  RESET_USERS_SELECTED_RECORDS,
  SET_AGENT_DETAILS_REQUEST,
  SET_AGENT_DETAILS_SUCCESS,
  SET_AGENT_DETAILS_FAILURE,
} from 'src/common/constants/actionTypes';

export const initialManageAgentsState = {
  loading: false,
  data: null,
  totalCount: 0,
  error: null,
  searchParams: {
    sorting: { sortingColumn: 'modifiedDate', isDescSort: true },
    paging: { startRowIndex: 0, pageSize: 20 },
    isApproved: true,
  },
  viewAgent: {
    loading: false,
    error: null,
    agentDetails: null,
  },
};

const usersSearchReducer = (state = initialManageAgentsState, action) => {
  switch (action.type) {
    case UPDATE_USERS_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          ...action.payload,
        },
      };
    case USERS_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case USERS_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        totalCount: action.payload.totalCount,
      };
    case USERS_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USERS_SELECTED_RECORDS:
      return {
        ...state,
        loading: false,
        usersSelectedRecords: action.payload,
      };
    case RESET_USERS_SELECTED_RECORDS:
      return {
        ...state,
        loading: false,
        resetUsersSelectedRecords: action.payload,
      };
    case SET_AGENT_DETAILS_REQUEST:
      return {
        ...state,
        viewAgent: {
          ...state.viewAgent,
          loading: true,
          agentDetails: null,
          error: null,
        },
      };
    case SET_AGENT_DETAILS_SUCCESS:
      return {
        ...state,
        viewAgent: {
          ...state.viewAgent,
          loading: false,
          agentDetails: action.payload.data,
        },
      };
    case SET_AGENT_DETAILS_FAILURE:
      return {
        ...state,
        viewAgent: {
          ...state.viewAgent,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default usersSearchReducer;
