import {
  ACTION_PAYMENT_METHODS_GET_DETAILS_SUCCESS,
  ACTION_PAYMENT_METHODS_GET_DETAILS_FAILURE,
} from '../../../common/constants/actionTypes';

const initialState = {
  paymentMethods: null,
};

export const paymentMethodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_PAYMENT_METHODS_GET_DETAILS_SUCCESS:
      return { ...state, paymentMethods: action.PaymentMethodsDetails };
    case ACTION_PAYMENT_METHODS_GET_DETAILS_FAILURE:
      return { ...state, paymentMethods: null };
    default:
      return state;
  }
};
