import React, { useEffect } from "react";
import { useAuth0  } from "@auth0/auth0-react";

const LeadCenterSSORedirect = () => {
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectTo = searchParams.get("redirectTo") || '';

    const checkSession = async () => {
      try {
        // Attempt to silently authenticate
        await getAccessTokenSilently()
        window.location.href = `${window.location.origin}/${redirectTo}`
      } catch (error) {
        await loginWithRedirect({
          prompt: 'none',
          appState: {
            targetUrl: `/${redirectTo}`
          },
        });
      }
    };

    if (!isAuthenticated) {
      checkSession();
    }
  }, [isAuthenticated, loginWithRedirect]);

    return (
        <div className="content-frame v2">
            Redirect
        </div>
    );
};

export default LeadCenterSSORedirect;
