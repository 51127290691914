import initialState from "../store/initialState";

export const agentReducer = (state = initialState.agents, action) => {
  switch (action.type) {
    case "GET_AGENTS_LIST_SUCCESS":
      return Object.assign({}, state, {
        agentsList: action.list,
      });
    case "GET_PRODUCERS_LIST_SUCCESS":
      return Object.assign({}, state, {
        producersList: action.list,
      });
    case "GET_UNAPPROVED_AGENTS_LIST":
      return Object.assign({}, state, {
        unapprovedAgents: action.payload,
      });
    case "GET_ADMIN_REQUEST_NOTIFICATIONS_SUCCESS":
      return Object.assign({}, state, {
        adminRequestNotifications: action.payload,
      });
    case "GET_ADMIN_REQUEST_NOTIFICATIONS_FAILURE":
      return Object.assign({}, state, {
        adminRequestNotifications: [],
      });
    case "GET_CALL_CAMPAIGNS_COUNT_SUCCESS":
      return Object.assign({}, state, {
        campaignsCountObj: action.campaignsCount,
      });
    case "GET_CALL_CAMPAIGNS_COUNT_FAILURE":
      return Object.assign({}, state, {
        campaignsCountObj: action.defaultCounts,
      });
    case "SET_CALL_TOGGLE_DISABLED_STATUS":
      return Object.assign({}, state, {
        toggleDisableStatus: action.status,
      });
    default:
      return state;
  }
};
