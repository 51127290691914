import initialState from "../store/initialState";

export const transactionsReducer = (
  state = initialState.transactionHistoryState,
  action
) => {
  switch (action.type) {
    case "GET_TRANSACTIONS_DATA_SUCCESS":
      return Object.assign({}, state, {
        transactionsData: action.data,
      });
    case "GET_TRANSACTIONS_TOTAL_COUNT_SUCCESS":
      return Object.assign({}, state, {
        transactionsTotalCount: action.count,
      });
    case "SET_TRANSACTIONS_LOADING":
      return Object.assign({}, state, {
        loading: action.loading,
      });
    case "GET_AUTOMATEDORDERS_DATA_SUCCESS":
      return Object.assign({}, state, {
        automatedOrdersData: action.data,
      });
    case "GET_AUTOMATEDORDERS_TOTAL_COUNT_SUCCESS":
      return Object.assign({}, state, {
        automatedOrdersTotalCount: action.count,
      });
    case "SET_AUTOMATEDORDERS_LOADING":
      return Object.assign({}, state, {
        automatedOrdersLoading: action.loading,
      });
    case "SET_INVENTORYSTATUSES":
      return Object.assign({}, state, {
        inventoryStatuses: action.statuses,
      });
    default:
      return state;
  }
};
