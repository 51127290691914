import {
  SET_IS_SHOW_MISCELLANEOUS_ORDER,
  SET_MISCELLANEOUS_ORDER_LEADTYPEIDIDENTITY,
} from '../actions/miscellaneousOrderActions';

const initialState = {
  isShowMiscellaneousOrder: false,
  miscellaneousOrderLeadTypeIdIdentity: null,
};

export const miscellaneousOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_SHOW_MISCELLANEOUS_ORDER: {
      return {
        ...state,
        isShowMiscellaneousOrder: action.payload,
      };
    }
    case SET_MISCELLANEOUS_ORDER_LEADTYPEIDIDENTITY: {
      return {
        ...state,
        miscellaneousOrderLeadTypeIdIdentity: action.payload,
      };
    }
    default:
      return state;
  }
};
