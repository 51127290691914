import {
  ACTION_SHOPPING_CART_GET_DETAILS_SUCCESS,
  ACTION_SHOPPING_CART_GET_DETAILS_FAILURE,
  ACTION_LEAD_GROUPS_GET_DETAILS_SUCCESS,
  ACTION_LEAD_GROUPS_GET_DETAILS_FAILURE,
  ACTION_PAYMENT_METHODS_GET_DETAILS_SUCCESS,
  ACTION_PAYMENT_METHODS_GET_DETAILS_FAILURE,
  ACTION_HEATMAP_GET_DETAILS_SUCCESS,
  ACTION_HEATMAP_GET_DETAILS_FAILURE,
  ACTION_ADD_CARD_SUCCESS,
  ACTION_ADD_CARD_FAILURE,
  ACTION_MAKE_DEFAULT_CARD_SUCCESS,
  ACTION_MAKE_DEFAULT_CARD_FAILURE,
  ACTION_DELETE_CARD_SUCCESS,
  ACTION_DELETE_CARD_FAILURE,
  ACTION_ADD_QUATITY_SUCCESS,
  ACTION_ADD_SHOPPINGCART_SUCCESS,
  ACTION_SET_GREETING,
  ACTION_SET_CART_WITH_DISCOUNT,
  ACTION_SET_DISCOUNT_FLAG,
  DELETE_CARTITEM_FROM_DROPDOWN,
  ACTION_SET_COUPON_DETAILS,
} from "../../../common/constants/actionTypes";
import initialState from "../store/initialState";

export const orderLeadsReducer = (state = initialState.orderLeads, action) => {
  switch (action.type) {
    case ACTION_SHOPPING_CART_GET_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        shoppingCart: action.ShoppingCartDetails,
      });
    case "GET_SHOPPING_CART_INFO_SUCCESS":
      return Object.assign({}, state, {
        shoppingCartDetails: action.cartInfo,
      });
    case "LAST_RESET_ORDER_EXPIRATION":
      return Object.assign({}, state, {
        resetOrderExpiration: action.expire,
      });
    case "ORDER_PURCHASED_SUCCESS":
      return Object.assign({}, state, {
        orderDetails: action.order,
      });
    case "GET_LEVELS_INFO_SUCCESS":
      return Object.assign({}, state, {
        levels: action.levels,
      });
    case ACTION_ADD_CARD_SUCCESS:
      return {};
    case ACTION_ADD_CARD_FAILURE:
      return {};
    case ACTION_DELETE_CARD_SUCCESS:
      return {};
    case ACTION_DELETE_CARD_FAILURE:
      return {};
    case ACTION_MAKE_DEFAULT_CARD_SUCCESS:
      return {};
    case ACTION_MAKE_DEFAULT_CARD_FAILURE:
      return {};
    case ACTION_SHOPPING_CART_GET_DETAILS_FAILURE:
      return {};
    case ACTION_LEAD_GROUPS_GET_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        leadGroups: action.LeadGroupsDetails,
      });
    case ACTION_LEAD_GROUPS_GET_DETAILS_FAILURE:
      return {};
    case ACTION_PAYMENT_METHODS_GET_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        paymentMethods: action.PaymentMethodsDetails,
      });
    case ACTION_PAYMENT_METHODS_GET_DETAILS_FAILURE:
      return {};
    case ACTION_HEATMAP_GET_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        heatmap: action.Heatmap,
      });
    case ACTION_ADD_QUATITY_SUCCESS:
      return Object.assign({}, state, {
        oreredQuantity: action.quantity,
      });
    case ACTION_ADD_SHOPPINGCART_SUCCESS:
      return Object.assign({}, state, {
        CartInformation: action.cartData,
      });
    case ACTION_HEATMAP_GET_DETAILS_FAILURE:
      return {};
    case ACTION_SET_GREETING:
      return Object.assign({}, state, {
        greeting: action.greeting,
      });
    case ACTION_SET_CART_WITH_DISCOUNT:
      return Object.assign({}, state, {
        cartWithDiscount: action.cartWithDiscount,
      });
    case ACTION_SET_DISCOUNT_FLAG:
      return Object.assign({}, state, {
        edit: action.edit,
      });
    case DELETE_CARTITEM_FROM_DROPDOWN:
      return Object.assign({}, state, {
        isDeletedFromHeaderCart: action.isDeletedFromHeaderCart,
      });
    case ACTION_SET_COUPON_DETAILS:
      return Object.assign({}, state, {
        couponDetails: action.couponDetails,
      });
    case "SET_ACTIVE_CART_ID":
      return Object.assign({}, state, {
        activeCartId: action.activeCartId,
      });
    case "SET_FILTERED_LEAD_TYPES_FETCHED":
      return {
        ...state,
        filteredLeadTypesBasedOnParam: action.filteredLeadTypes,
      };
    default:
      return state;
  }
};
