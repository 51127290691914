/* eslint-disable react-hooks/rules-of-hooks */
export let ILS_API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const TOKEN_NAME = 'ils_token_id';
export const API_URL = `${ILS_API_BASE_URL}`;
export const applicationManagement = 'applicationManagement';
export const leadManagement = 'leadManagement';
export const couponManagement = 'couponManagement'

export const storageEngine = localStorage; // LocalStorage || sessionStorage

export function getFetchConfigWithToken(actionType, props = {}, refToken) {
  const accesstoken = localStorage.getItem("accessToken");

  const config = {
    method: actionType,
    headers: {
      Authorization: `Bearer ${accesstoken}`,
      'Content-Type': 'application/json',
      Ref_Token: `${refToken}`
    }
  };
  return { ...config, ...props };
}

export function postFetchConfigWithToken(actionType, reqBody, props = {}, refToken) {
  const accesstoken = localStorage.getItem("accessToken");

  const config = {
    method: actionType,
    headers: {
      Authorization: `Bearer ${accesstoken}`,
      'Content-Type': 'application/json',
      Ref_Token: `${refToken}`
    },
    body: JSON.stringify(reqBody)
  };
  return { ...config, ...props };
}
