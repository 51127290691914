import { SET_BANNERS_DATA } from '../actions/bannerAction';

const initialState = {
  bannersData: null,
};

export const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BANNERS_DATA: {
      return {
        ...state,
        bannersData: action.payload,
      };
    }
    default:
      return state;
  }
};
