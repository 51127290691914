import initialState from "../store/initialState";

export const faqReducer = (state = initialState.faq, action) => {
  switch (action.type) {
    case "SET_FAQ_KEYWORD_SEARCHED": {
      return {
        ...state,
        keywordSearched: action.keywordSearched,
      };
    }
    default:
      return state;
  }
};
