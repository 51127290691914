export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_AUTH_AUTHORITY_URL,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  client_secret: process.env.REACT_APP_IDENTITY_SECRET,
  redirect_uri: window.location.origin + '/signin-oidc',
  silent_redirect_uri: window.location.origin  + '/silent',
  post_logout_redirect_uri: window.location.origin + '/signout-oidc',
  response_type: "code",
  loadUserInfo: true,
  scope: process.env.REACT_APP_SCOPE
};
