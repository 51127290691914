import { ACTION_APPLICATION_ERROR } from "../../../common/constants/actionTypes";
import initialState from "../store/initialState";

export function applicationErrorReducer(state = initialState.application.errors, action) {
    switch (action.type) {
        case ACTION_APPLICATION_ERROR:
            return [
                ...state,
                { region: action.region, component: action.component, error: action.error }
            ];
        default:
            return state;
    }

}