import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { setIsContactSupportModal } from "src/app/redux/actions/actions";
import logo from "src/assets/Images/LeadCENTER_Rev_RGB.png";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import {
  faQuestion,
  faSignOut,
  faAngleDown,
} from "@fortawesome/pro-light-svg-icons";
import {useAuth0} from '@auth0/auth0-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactSupport from "src/app/containers/Header/ContactSupport";
const Header = ({ fullName }) => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const handleHelpClick = () => {
    dispatch(setIsContactSupportModal(true));
  };
  const LogoutCLick = () => {
    logout({ logoutParams: { returnTo: process.env.REACT_APP_IDENTITY_SIGNOUT_URL } });
  };
  return (
    <React.Fragment>
      <ContactSupport />
      <header className="bg-blue custom-header-multi-bu">
        <div>
          <img src={logo} alt="LeadCENTER logo" />
        </div>
        <div>
          <CDropdown inNav className="c-header-nav-items mr-2" direction="down">
            <CDropdownToggle
              className="pl-1 pr-1 px-sm-2 dropdown-toggle colorWhite"
              caret={false}
            >
              {fullName}
              <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
            </CDropdownToggle>
            <CDropdownMenu className="pt-0 user-menu" placement="bottom-end">
              <CDropdownItem onClick={handleHelpClick}>
                <FontAwesomeIcon icon={faQuestion} className="submenu-icon" />
                Need Help
              </CDropdownItem>
              <CDropdownItem onClick={LogoutCLick}>
                <FontAwesomeIcon icon={faSignOut} className="submenu-icon" />
                Log Off
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
