import { IDENTITY_CONFIG } from ".././../../common/constants/authConst";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { fetchDeleteJson, fetchGetJson } from "src/app/helpers/httpHelper";
import { leadManagement } from "src/config";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import AuthAxios from "src/app/helpers/AxiosAuth";

var key = `oidc.user:${process.env.REACT_APP_AUTH_AUTHORITY_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`;
const AUTH_API_VERSION = "v2.0";
const cookies = new Cookies();

class AuthService {
  UserManager;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });

    if (process.env.REACT_APP_BUILD_ENV !== "Production") {
      Log.logger = console;
      Log.level = Log.DEBUG;
    }

    this.UserManager.events.addUserLoaded((user) => {
      if (window.location.href.indexOf("signin-oidc") !== -1) {
        this.getRolesAndPermission(user);
      }
    });
    this.UserManager.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log("token expired, trying silent signIn");
      this.signinSilent();
    });

    this.UserManager.events.addUserSignedOut(() => {
      console.log("user signed out");
      this.logout();
    });
  }
  _authAPIRequest = async (
    path,
    method = "GET",
    body = null,
    isExtranlLogin = false
  ) => {
    let user = null;
    try {
      if (this.isAuthenticated()) {
        user = await this.getUser();
      }
    } catch (error) {
      user = null;
    }
    const opts = {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    if (user) {
      opts.headers.Authorization = `Bearer ${user.access_token}`;
    }
    if (body) {
      opts.body = JSON.stringify(body);
    }

    let url = `${process.env.REACT_APP_AUTH_AUTHORITY_URL}`;
    if (isExtranlLogin) {
      url = `${url}/external${path}`;
    } else {
      url = `${url}/api/${AUTH_API_VERSION}/account${path}`;
    }

    return fetch(url, opts);
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      Log.WARN("User not found. Attempting signinRedirectCallback..");
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  signinRedirect = () => {
    localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect();
  };

  signinRedirectCallback = async () => {
    try {
      this.UserManager.signinRedirectCallback();
    } catch (err) {
      console.log("SignInRedirect Error" + err);
      this.UserManager.clearStaleState();
      localStorage.clear();
      throw new Error(err);
    }
  };

  signinSilent = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let clientId = params.get("client_id");
    
    if (clientId === process.env.REACT_APP_SSO_MEDICARE_IDENTITY_CLIENT_ID)
    {
      localStorage.setItem("IsMedicareCenterSso","true");
    }
    else if(clientId === process.env.REACT_APP_SSO_IDENTITY_CLIENT_ID)
    {
      localStorage.setItem("isSSO","true");
    }

    let user;
    try {
      user = await this.UserManager.signinSilent();
    } catch (err) {
      console.log("SilentSignIn Error" + err);
      Swal.fire({
        text: "Your current session has expired, please login again.",
        icon: "info",
        allowOutsideClick: false,
      }).then((res) => {
        if (res.isConfirmed) {
          this.logout();
        }
      });
      return;
    }
    Log.debug("authService: signinSilent success!", user);
    return user;
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token")
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      this.UserManager.removeUser();
      this.UserManager.clearStaleState();
      const errorMsg = localStorage.getItem("errorMsg");
      if (errorMsg) {
        const code = localStorage.getItem("errorCode");
        localStorage.clear();
        localStorage.setItem("errorMsg", errorMsg);
        localStorage.setItem("errorCode", code);
        window.location.replace("/error");
      } else {
        localStorage.clear();
      window.location = cookies.get('client_url') + "/login";
      }
    });
  };

  isAuthenticated = () => {
    var token = localStorage.getItem(key);
    if (token) {
      const isAuthDone = JSON.parse(token);
      if (!!isAuthDone && !!isAuthDone.access_token) {
        return true;
      }
    }
    return false;
  };

  getRolesAndPermission = async (user) => {
    await this.getRoles().then((results) => {
      if (results.status !== 200) {
        results.text().then((response) => {
          localStorage.removeItem(key);
          localStorage.setItem("errorMsg", response);
          localStorage.setItem("errorCode", results.status);
          this.signoutRedirectCallback();
        });
      } else {
        results.json().then((data) => {
          if (data.IsNewUser)
          {
            window.location.replace("/bu");
          }
          else
          {
            this.setValues(data);
          }
        });
      }
    });
  };

  handleIncreasingLoginCount = () => {
    return AuthAxios.post(`${process.env.REACT_APP_BASE_URL}applicationManagement/users/login`)
  }

  getCartInfoAndClear = () => {
    fetchGetJson(leadManagement + "/cart/current")
      .then((json) => {
        this.handleCancelCart(json.cartId);
      })
      .catch((err) => console.log(err));
  };
  handleCancelCart = (cart) => {
    fetchDeleteJson(leadManagement + "/cart/" + cart)
      .then((json) => {
        window.location.replace("/marketplace");
      })
      .catch((error) => {});
  };

  setValues = (json) => {
    localStorage.setItem("ref_token", json.referenceToken);
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("roper", JSON.stringify(json));
    localStorage.setItem(
      "isTermsAndConditionsRequired",
      json.isTermsAndConditionsRequired
    );
    localStorage.setItem(
      "isShowLeadDispositionNotification",
      json?.isShowLeadDispositionNotification
    );
    this.handleIncreasingLoginCount();
    if (json.isNewUser) {
      window.location.replace("/bu");
    }
    else {
      this.getCartInfoAndClear();
    }
  };

  getRoles = async () => {
    let isSSOFlag = localStorage.getItem("isSSO") === "true" ? "true" : "false";
    let isMedicareCenterSso = localStorage.getItem("IsMedicareCenterSso") === "true" ? "true" : "false";
    return this._authRequest(
      process.env.REACT_APP_BASE_URL,
      "applicationManagement/permissions?isSSO=" + isSSOFlag + "&IsMedicareCenterSso=" + isMedicareCenterSso
    );
  };

  updateAccountMetadata = async (values) =>
    this._authAPIRequest("/update", "PUT", values);

  updateAccountPassword = async (values) =>
    this._authAPIRequest("/updatepassword", "PUT", values);

  //TODO Need to do In MVP 2
  requestPasswordReset = async (values) =>
    this._authAPIRequest("/forgotpassword", "POST", values);

  resetPassword = async (values) =>
    this._authAPIRequest("/resetpassword", "POST", values);

  validatePasswordResetToken = async (values) =>
    this._authAPIRequest("/validateresetpasswordtoken", "POST", values);

  forgotUsername = async (values) =>
    this._authAPIRequest("/forgotusername", "POST", values);

  sendConfirmationEmail = async (values) =>
    this._authAPIRequest("/resendconfirmemail", "POST", values);

  confirmEmail = async (values) =>
    this._authAPIRequest("/confirmemail", "POST", values);

  loginUser = async (values) => {
    return this._authAPIRequest("/login", "POST", values);
  };

  loginUserWithClinetID = async (values, isClinetId) => {
    return this._authAPIRequest("/login", "POST", values, isClinetId);
  };

  registerUser = async (values) =>
    this._authAPIRequest("/register", "POST", values);

  logoutUser = async (logoutId) => {
    return this._authAPIRequest(`/logout?logoutId=${logoutId}`);
  };
  _authRequest = async (url, path, actionType = "GET", body = null) => {
    let user = null;
    try {
      if (this.isAuthenticated()) {
        user = await this.getUser();
      }
    } catch (error) {
      user = null;
    }

    const opts = {
      method: actionType,
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (user) {
      opts.headers.Authorization = `Bearer ${user.access_token}`;
    }
    if (body) {
      opts.body = JSON.stringify(body);
    }

    return fetch(`${url}${path}`, opts);
  };

  getServerError = async (errorId) => fetch(`/error?errorId=${errorId}`);
}

export default new AuthService();
