import Multiselect from "multiselect-react-dropdown";
import React from "react";

const MultiSelectDropDown = (props) => {
    const {options, onSelect, onRemove, maxLimit, placeholder, values} = props;
    return(
        <Multiselect 
        customCloseIcon={<>x</>}
        onSelect={onSelect}
        onRemove={onRemove}
        options={options}
        showCheckbox
        closeOnSelect={false}
        avoidHighlightFirstOption={true}
        displayValue={`label`}
        selectionLimit={maxLimit}
        placeholder={values?.length > 0 ? '' : placeholder}
        />
    )
}

export default MultiSelectDropDown;