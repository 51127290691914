import {
    ACTION_SIDEBAR_GET_DETAILS_FAILURE,
    ACTION_SIDEBAR_GET_DETAILS_SUCCESS
} from "../../../common/constants/actionTypes";
import initialState from "../store/initialState";

export const sidebarReducer = (state = initialState.sidebarMenu, action) => {
    switch (action.type) {
        case ACTION_SIDEBAR_GET_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                sidebarMenu: action.sidebarDetails
            })
        case ACTION_SIDEBAR_GET_DETAILS_FAILURE:
            return {};
        default:
            return state;
    }
}