/* eslint-disable react-hooks/rules-of-hooks */
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { icons } from "./assets/icons";
import AppWrapper from "./AppWrapper";

React.icons = icons;
ReactDOM.render(
  <AppWrapper />,
  document.getElementById("root")
);

serviceWorker.unregister();
