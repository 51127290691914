import {
  AGENT_REFERRAL_REQUEST,
  AGENT_REFERRAL_SUCCESS,
  AGENT_REFERRAL_FAILURE,
} from '../actions/agentReferralModalAction';
import initialState from '../store/initialState';

export const agentReferralModalReducer = (
  state = initialState.agentReferralModalDetails,
  action,
) => {
  switch (action.type) {
    case AGENT_REFERRAL_REQUEST:
      return { ...state, loading: true, error: null };
    case AGENT_REFERRAL_SUCCESS:
      return { ...state, loading: false, modalData: action.payload };
    case AGENT_REFERRAL_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
