import {
  WELCOME_USER_DO_NOT_SHOW_CHECKED,
  WELCOME_USER_FAILURE,
  WELCOME_USER_REQUEST,
  WELCOME_USER_SUCCESS,
} from "../actions/agentWelcomeModalActions";
import initialState from "../store/initialState";

export const agentWelcomeModalReducer = (
  state = initialState.welcomeModalDetails,
  action,
) => {
  switch (action.type) {
    case WELCOME_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case WELCOME_USER_SUCCESS:
      return { ...state, loading: false, modalData: action.payload };
    case WELCOME_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case WELCOME_USER_DO_NOT_SHOW_CHECKED:
      return { ...state, isDoNotShowChecked: action.payload };
    default:
      return state;
  }
};
