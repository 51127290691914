/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import './loader.scss';

export default function CmLoader(props) {
  const { customText } = props;
  const [loaderINFO, setLoaderINFO] = useState('Please Wait...');

  useEffect(() => {
    if(customText?.length > 0) {
      setLoaderINFO(customText);
    } else {
      setLoaderINFO('Please Wait...')
    }
  },[customText])
  return (
    <div className="loaderContianer">
      <div className="overlayLoader"></div>
      <div className="loaderContent">
        <div className="loader"></div>
        <div className="loaderInfo">{loaderINFO}</div>
      </div>
    </div>
  );
}
