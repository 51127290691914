import AuthAxios from "./AxiosAuth";

export function fetchGetJson(url) {
  return AuthAxios.get(url).then((res) => {
    return res.data;
  });
}

export function fetchPostJson(url, requestBody) {
  return AuthAxios.post(url, requestBody)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err?.response?.data;
    });
}

export function fetchDeleteJson(url) {
  return AuthAxios.delete(url)
    .then((_results) => {
      return true;
    })
    .catch((_error) => {
      return false;
    });
}

export const constructPageUrl = (filters) => {
  const params = new URLSearchParams();
  
  for (const key in filters) {
    if (filters[key] !== undefined && filters[key] !== "") {
      params.append(key, filters[key]);
    }
  }

  return `?${params.toString()}`;
};

export const constructPageUrlVendorCredits = (filters) => {
  const params = new URLSearchParams();
 
  for (const key in filters) {
    if (filters[key] !== undefined && filters[key] !== '') {
      if (Array.isArray(filters[key])) {
        filters[key].forEach((value) => {
          params.append(key, value);
        });
      } else {
        params.append(key, filters[key]);
      }
    }
  }
 
  return `?${params.toString()}`;
};