import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin('ILC-UI');
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATIONKEY,
    extensions: [reactPlugin]
  },
});
var telemetryInitializer = (envelope) => {
    envelope.tags["ai.cloud.role"] = process.env.REACT_APP_CLOUD_ROLE_NAME;
    envelope.tags["ai.cloud.roleInstance"] = process.env.REACT_APP_CLOUD_ROLE_INSTANCE;
  }

appInsights.loadAppInsights();
appInsights.addTelemetryInitializer(telemetryInitializer);
export { reactPlugin, appInsights };