import { useEffect } from 'react';

const FreshChatWidget = () => {
  const roper = JSON.parse(localStorage.getItem('roper'));
  const agentName = `${roper?.firstName} ${roper?.lastName}`;
  const npn = roper?.npn;
  const agentEmail = roper?.email;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_FRESH_CHAT_SCRIPT_URL;
    script.setAttribute('chat', 'true');
    script.async = true;

    document.head.appendChild(script);

    script.onload = () => {
      if (
        window.fcWidget &&
        window.fcWidget.user &&
        typeof window.fcWidget.user.setProperties === 'function'
      ) {
        // Set user properties once FreshChat is initialized
        window.fcWidget.user.setProperties({
          agentName: agentName ?? '',
          npn: npn ?? '',
          agentEmail: agentEmail ?? '',
        });
      }
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null;
};

export default FreshChatWidget;
