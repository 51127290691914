import {
  ACTION_MANAGE_LEADS_GET_DETAILS_FAILURE,
  ACTION_MANAGE_LEADS_GET_DETAILS_SUCCESS,
  ACTION_SET_LEADS_REMINDERS,
  SET_TAB_ON_LEADS,
} from "../../../common/constants/actionTypes";
import initialState from "../store/initialState";

export const manageLeadsReducer = (
  state = initialState.manageLeads,
  action
) => {
  switch (action.type) {
    case ACTION_MANAGE_LEADS_GET_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        manageLeads: action.manageLeadsDetails,
      });
    case ACTION_MANAGE_LEADS_GET_DETAILS_FAILURE:
      return {};
    case "ACTION_SELECTED_RECORDS":
      return Object.assign({}, state, {
        records: action.records,
      });
    case "ACTION_SELECTED_LEADIDS":
      return Object.assign({}, state, {
        leadIdIdentities: action.leadIdIdentities,
      });
    case "ACTION_FILTER_SEARCH_DATA":
      return Object.assign({}, state, {
        searchData: action.searchData,
      });
    case "LEAD_VENDORS_FETCHED":
      return Object.assign({}, state, {
        leadVendors: action.leadVendors,
      });
    case "INTEREST_ANNUITY_DATA":
      return Object.assign({}, state, {
        interestAnnuity: action.interestAnnuity,
      });
    case "INTEREST_HEALTH_DATA":
      return Object.assign({}, state, {
        interestHealth: action.interestHealth,
      });
    case "INTEREST_LIFE_DATA":
      return Object.assign({}, state, {
        interestLife: action.interestLife,
      });
    case "INTEREST_MEDICARE_DATA":
      return Object.assign({}, state, {
        interestMedicare: action.interestMedicare,
      });
    case "OBJECTION_DATA":
      return Object.assign({}, state, {
        objection: action.objection,
      });
    case "STATE_DATA":
      return Object.assign({}, state, {
        states: action.states,
      });
    case "COUNTIES_DATA":
      return Object.assign({}, state, {
        counties: action.counties,
      });
    case "FILTER_DATA_URL":
      return Object.assign({}, state, {
        filterURL: action.url,
      });
    case "LEADS_PAGE_NUMBER":
      return Object.assign({}, state, {
        LeadPageNumber: action.page,
      });
    case "SET_LEAD_STATUSES":
      return Object.assign({}, state, {
        leadStatuses: action.list,
      });
    case ACTION_SET_LEADS_REMINDERS:
      return Object.assign({}, state, {
        reminders: action.reminders,
      });
    case SET_TAB_ON_LEADS:
      return Object.assign({}, state, {
        activeTab: action.activeTab,
      });
    case "SET_ALL_DROPDOWNS_LIST":
      return Object.assign({}, state, {
        dropdowns: action.dropdownData,
      });
    case "SET_NOTES_LOADING":
      return Object.assign({}, state, {
        notesLoading: action.loading,
      });
    case "SET_REMINDER_LOADING":
      return Object.assign({}, state, {
        remindersLoading: action.loading,
      });
    case "SET_NOTES_LIST":
      return Object.assign({}, state, {
        notes: action.notes,
      });
    case "SET_REMINDER_LIST":
      return Object.assign({}, state, {
        remindersList: action.reminders,
      });
    case "SET_REMINDER_TOTAL_COUNT":
      return Object.assign({}, state, {
        reminderTotalCount: action.count,
      });
    case "SET_NOTES_TOTAL_COUNT":
      return Object.assign({}, state, {
        notesTotalCount: action.count,
      });
    default:
      return state;
  }
};
