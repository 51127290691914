import {
    ACTION_SHOPPING_CART_REMOVE_LEADORDER_ITEM_SUCCESS,
    ACTION_SHOPPING_CART_REMOVE_LEADORDER_ITEM_FAILURE,
} from '../../../common/constants/actionTypes';
import initialState from '../store/initialState';

export const shoppingCartReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_SHOPPING_CART_REMOVE_LEADORDER_ITEM_SUCCESS:
            return Object.assign({}, state, {
                shoppingCart: action.LeadOrderItem
            })
        case ACTION_SHOPPING_CART_REMOVE_LEADORDER_ITEM_FAILURE:
            return {}
        default:
            return {}
    }
}