import React from "react";
import { useDispatch } from "react-redux";
import footerLogo from "src/assets/Images/only-integrity.png";
import { setIsContactSupportModal } from "src/app/redux/actions/actions";
import { Link } from "react-router-dom";
import ContactSupport from "src/app/containers/Header/ContactSupport";

const Footer = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setIsContactSupportModal(true));
  };
  return (
    <React.Fragment>
      <ContactSupport />
      <div className="custom-footer-multibu">
        <div>
          <span className="ftr-links" onClick={handleClick}>
            Need Help?
          </span>
          <span className="separator">|</span>
          <span className="ftr-links">
            <Link to="/terms" className="ftr-links">Terms of Use</Link>
          </span>
          <span className="separator">|</span>
          <span className="ftr-links">
            <Link to="/privacy" className="ftr-links">Privacy Policy</Link>
          </span>
        </div>
        <div>
          <img src={footerLogo} alt={`Integrity logo`} />
          <span>
            {" "}
            &copy;&nbsp; {new Date().getFullYear()}&nbsp;Integrity Marketing
            Group. All rights reserved.
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;