import initialState from '../store/initialState';

export const userCreditsReducer = (state = initialState.vouchers, action) => {
  switch (action.type) {
    case 'SET_AVAILABLE_CREDIT_BALANCE':
      return { ...state, creditBalance: action.credit };
    default:
      return state;
  }
};
