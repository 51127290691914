import initialState from "../store/initialState";

export const couponReducer = (state = initialState.coupons, action) => {
  switch (action.type) {
    case "GET_COUPONS_LIST_SUCCESS":
      return Object.assign({}, state, {
        couponsList: action.list,
      });
    case "GET_COUPONS_LIST_FAILURE":
      return Object.assign({}, state, {
        couponsList: {},
      });
    case "ACTION_CHECKED_COUPONS":
      return Object.assign({}, state, {
        records: action.records,
      });
    case "SET_FETCHED_COUPON_INFORMATION":
      return {...state, couponInformation: action.couponInformation}
    default:
      return state;
  }
};
