import initialState from '../store/initialState';

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOGIN_STATUS': {
      return {
        ...state,
        isLogin: action.isLogin,
      };
    }
    case 'STATES_FETCHED': {
      return {
        ...state,
        states: action.states,
      };
    }
    case 'COUNTYS_FETCHED': {
      return {
        ...state,
        countys: action.countys,
      };
    }
    case 'LEAD_TYPES_FETCHED': {
      return {
        ...state,
        leadTypes: action.leadTypes,
      };
    }
    case 'LEAD_GROUP_TYPES_FETCHED': {
      return {
        ...state,
        leadGroupTypes: action.leadGroupTypes,
      };
    }
    case 'BUSINESS_UNITS_FETCHED': {
      return {
        ...state,
        businessUnits: action.businessUnits,
      };
    }
    case 'GET_ROLES_PERMISSIONS': {
      return {
        ...state,
        roles: action.roles,
      };
    }
    case 'GET_STATUS_OF_PERMISSIONS': {
      return {
        ...state,
        isHavePermission: action.permission,
      };
    }
    case 'SET_SPINNER_STATUS': {
      return {
        ...state,
        loadingStatus: action.spinterm,
      };
    }
    case 'GET_INTEREST_ANNUITY_DATA':
      return {
        ...state,
        interestAnnuity: action.interestAnnuity,
      };
    case 'GET_INTEREST_HEALTH_DATA':
      return {
        ...state,
        interestHealth: action.interestHealth,
      };
    case 'GET_INTEREST_LIFE_DATA':
      return {
        ...state,
        interestLife: action.interestLife,
      };
    case 'GET_INTEREST_MEDICARE_DATA':
      return {
        ...state,
        interestMedicare: action.interestMedicare,
      };
    case 'GET_OBJECTION_DATA':
      return {
        ...state,
        objection: action.objection,
      };
    case 'GET_LEAD_STATUS_DATA':
      return {
        ...state,
        leadStatus: action.leadStatus,
      };
    case 'GET_LEAD_STATUSES_DATA':
      return {
        ...state,
        leadStatuses: action.leadStatuses,
      };
    case 'GET_LEAD_VENDOR_DATA':
      return {
        ...state,
        leadVendors: action.leadVendors,
      };
    case 'SET_LEAD_PARTNERS':
      return {
        ...state,
        leadPartners: action.leadPartners,
      };
    case 'GET_PAYMENT_FROM_USER':
      return {
        ...state,
        isPaymentFromUser: action.bool,
      };
    case 'SET_USER_ISHAVING_PAYMENT_METHODS':
      return {
        ...state,
        ishavingPaymentmethods: action.payload,
      };
    case 'SET_USER_PAYMENT_METHODS_INFORMATION':
      return {
        ...state,
        agentPaymentInfo: action.payload,
      };
    case 'SET_IS_LOGOUT_MODAL':
      return {
        ...state,
        isLogoutModal: action.bool,
      };
    case 'SET_VOUCHER_AMOUNT':
      return {
        ...state,
        voucherAmount: action.amt,
      };
    case 'SET_IS_ADD_FUNDS_MODAL':
      return {
        ...state,
        isAddFundsModal: action.bool,
      };
    case 'SET_IS_AUTOMATE_MODAL':
      return {
        ...state,
        isAutomateModal: action.bool,
      };
    case 'SET_IS_AUTOMATE_AND_SAVE_MODE':
      return {
        ...state,
        isAutomateAndSaveMode: action.bool,
      };
    case 'SHOW_AUTOMATE_AND_SAVE_TURN_OFF_POPUP':
      return {
        ...state,
        showAutomateAndSaveTurnOffPopup: action.bool,
      };
    case 'SET_IS_CONTACT_SUPPORT_MODAL':
      return {
        ...state,
        isContactSupportModal: action.bool,
      };
    case 'SET_USER_TOGGLE_PREFERENCES':
      return {
        ...state,
        togglePreferences: action.payload,
      };
    case 'SET_PRODUCTS_LIST':
      return {
        ...state,
        products: action.products,
      };
    case 'SET_PRODUCT_CATEGORY_LIST':
      return {
        ...state,
        productCategories: action.productCategories,
      };
    case 'SET_PURCHASE_TYPES_LIST':
      return {
        ...state,
        purchaseTypes: action.purchaseTypes,
      };
    case 'SET_CASH_DETAILS':
      return {
        ...state,
        cashDetails: action.cashDetails,
      };
    case 'IS_GET_CREDIT_DETAILS':
      return {
        ...state,
        isGetCreditDetails: action.bool,
      };
    case 'IS_ADD_FUNDS_FOR_REALTIME':
      return {
        ...state,
        isAddFundsForRealtime: action.bool,
      };
    case 'IS_RELOAD_PAYMENTS':
      return {
        ...state,
        isReloadPayments: action.bool,
      };
    case 'GET_REFRESHED_CAMPAIGNS_COUNT':
      return {
        ...state,
        refreshCampaignsCount: action.bool,
      };
    case 'SET_CREDIT_REQUEST_STATUSES':
      return {
        ...state,
        creditRequestStatuses: action.creditRequestStatuses,
      };
    case 'SET_LEADS_ASSIGN_MODE':
      return {
        ...state,
        leadsAssignMode: action.mode,
      };
    case 'SET_APPLICATION_BUSINESS_UNITS':
      return {
        ...state,
        applicationBusinessUnits: action.businessUnits,
      };
    case 'SET_USER_REGISTERED_BUSINESS_UNITS':
      return {
        ...state,
        userBusinessUnits: action.userBusinessUnits,
      };
    case 'DAYSOFWEEK_SUCCESS':
      return {
        ...state,
        daysOfWeek: action.daysOfWeek,
      };
    case 'SET_USER_APP_INTEGRATIONS':
      return {
        ...state,
        userAppIntegrations: action.userAppIntegrations,
      };
    case 'SET_IS_BANNERS_VISIBLE':
      return {
        ...state,
        isBannersVisible: action.isBannersVisible,
      };
    case 'SET_LAYOUT_HEIGHTS':
      return {
        ...state,
        layoutHeights: action.layoutHeights,
      };
    case 'SET_LIST_SCREEN_ERROR':
      return {
        ...state,
        isListScreenError: action.isListScreenError,
      };
    case 'SET_SHOW_AGENT_REFERRAL_MODAL':
      return {
        ...state,
        showAgentReferralModal: action.showAgentReferralModal,
      };
    case 'SET_USER_LEAD_PARTNERS':
      return {
        ...state,
        userLeadPartners: action.userLeadPartners,
      };
    default:
      return state;
  }
};
