import React from "react";
import { Provider } from "react-redux";
import HttpsRedirect from "react-https-redirect";
import { Auth0Provider } from "@auth0/auth0-react";
import confugureStore from "./app/redux/store/index";
import App from "./app/App";

const AppWrapper = () => {
  const store = confugureStore({});
  return (
    <Provider store={store}>
      <HttpsRedirect>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH_AUTHORITY_URL}
          clientId={process.env.REACT_APP_IDENTITY_CLIENT_ID}
          authorizationParams={{
            redirect_uri: process.env.REACT_APP_IDENTITY_SIGNIN_URL,
            audience: process.env.REACT_APP_IDENTITY_AUDIENCE,
          }}
          cacheLocation="localstorage"
          useRefreshTokens={true}
          onRedirectCallback={(appState) => {
            const targetUrl = appState?.targetUrl || '/';
            window.history.replaceState({ appState }, document.title, targetUrl);
          }}
        >
          <App />
        </Auth0Provider>
      </HttpsRedirect>
    </Provider>
  );
};

export default AppWrapper;
