export const SET_IS_SHOW_MISCELLANEOUS_ORDER =
  'SET_IS_SHOW_MISCELLANEOUS_ORDER';
export const SET_MISCELLANEOUS_ORDER_LEADTYPEIDIDENTITY =
  'SET_MISCELLANEOUS_ORDER_LEADTYPEIDIDENTITY';

export const setIsShowMiscellaneousOrder = (payload) => {
  return { type: SET_IS_SHOW_MISCELLANEOUS_ORDER, payload };
};

export const setMiscellaneousOrderLeadTypeIdIdentity = (payload) => {
  return { type: SET_MISCELLANEOUS_ORDER_LEADTYPEIDIDENTITY, payload };
};
